import expressions from 'angular-expressions';

export default function angularParser(tag) {
  if (tag === '.') {
    return {
      get: function(s) {
        return s;
      }
    };
  }
  const expr = expressions.compile(tag.replace(/(’|“|”|‘)/g, "'"));
  return {
    get: function(s) {
      return expr(s);
    }
  };
}
