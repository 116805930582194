import PizZip from 'pizzip';
import docxtemplater from 'docxtemplater';
import angularParser from './angularParser';

class Cv {
  constructor() {
    this.el = this._getElements();
    this._addListeners();
  }

  _getElements() {
    return {
      form: document.getElementById('js-form'),
      addRowButton: document.querySelectorAll('.js-add-row'),
      name: document.getElementById('js-cv-name'),
      birthdate: document.getElementById('js-cv-birthdate'),
      residence: document.getElementById('js-cv-residence'),
      nationality: document.getElementById('js-cv-nationality'),
      languagePrimary: document.getElementById('js-cv-language-primary'),
      languageSecondary: document.getElementById('js-cv-language-secondary'),
      hobbies: document.getElementById('js-cv-hobbies'),
      linkedin: document.getElementById('js-cv-linkedin'),
      github: document.getElementById('js-cv-github'),
      profileDescription: document.getElementById('js-cv-profile-description'),
      skills: document.getElementById('js-cv-skills')
    };
  }

  _generateFile = () => {
    this._loadFile('input.docx', (error, content) => {
      if (error) {
        throw error;
      }
      var zip = new PizZip(content);
      var doc = new docxtemplater().loadZip(zip);
      doc.setOptions({
        linebreaks: true,
        parser: angularParser
      });

      doc.setData(this.data);

      try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
      } catch (error) {
        var e = {
          message: error.message,
          name: error.name,
          stack: error.stack,
          properties: error.properties
        };
        console.log(JSON.stringify({ error: e }));
        // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
        throw error;
      }
      var out = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      }); //Output the document using Data-URI
      saveAs(out, 'output.docx');
    });
  };

  _addListeners() {
    this.el.form.addEventListener('submit', this._handleFormSubmit);

    for (const addRowButtons of this.el.addRowButton) {
      addRowButtons.addEventListener('click', this._addRow);
    }
  }

  _addRow = e => {
    e.preventDefault();
    const parent = e.target.parentNode;
    const row = parent.querySelector('.js-row');

    const clone = row.cloneNode(true);
    const deleteButton = this._generateDeleteButton();

    // Empty values of cloned inputs & textareas
    const inputs = clone.childNodes[1].querySelectorAll('input');
    inputs.forEach(input => {
      input.value = '';
    });

    const textareas = clone.childNodes[1].querySelectorAll('textarea');
    textareas.forEach(textarea => {
      textarea.value = '';
    });

    // Add delete button + insert to DOM
    clone.firstElementChild.appendChild(deleteButton);
    row.parentNode.appendChild(clone);

    // Add listener to newly created delete button
    const deleteButtons = parent.querySelectorAll('.js-delete-row');
    deleteButtons[deleteButtons.length - 1].addEventListener(
      'click',
      this._handleDeleteRowClick
    );
  };

  _generateDeleteButton() {
    const fragment = document.createDocumentFragment();
    const li = document.createElement('li');
    const button = document.createElement('button');

    li.className = 'form__item form__item--delete';
    button.className = 'button button--delete js-delete-row';
    button.innerHTML = 'x';

    fragment.appendChild(li);
    li.appendChild(button);
    return fragment;
  }

  _handleDeleteRowClick = e => {
    e.preventDefault();
    const row = e.target.parentNode.parentNode.parentNode;
    row.remove();
  };

  _handleFormSubmit = e => {
    e.preventDefault();
    this._getData();
    this._getRowData();
    this._generateFile();
  };

  _addRowData(elements, data = []) {
    const names = Object.keys(elements);
    const hasValue = elements[names[0]][0].value;

    // if first item has no value, return empty array
    if (!hasValue) return data;

    // Maps all values from the inputs into an array with objects
    names.map(type => {
      elements[type].forEach((item, index) => {
        data[index] = { ...data[index], [type]: item.value };
      });
    });

    return data;
  }

  _getRowData() {
    const educationEl = {
      name: document.querySelectorAll('.js-row-education-name'),
      dateStart: document.querySelectorAll('.js-row-education-date-start'),
      dateEnd: document.querySelectorAll('.js-row-education-date-end')
    };
    const courseEl = {
      name: document.querySelectorAll('.js-row-course-name'),
      dateStart: document.querySelectorAll('.js-row-course-date-start'),
      dateEnd: document.querySelectorAll('.js-row-course-date-end')
    };
    const experienceEl = {
      title: document.querySelectorAll('.js-row-experience-title'),
      company: document.querySelectorAll('.js-row-experience-company'),
      dateStart: document.querySelectorAll('.js-row-experience-date-start'),
      dateEnd: document.querySelectorAll('.js-row-experience-date-end'),
      description: document.querySelectorAll('.js-row-experience-description'),
      skills: document.querySelectorAll('.js-row-experience-skills')
    };

    const educations = this._addRowData(educationEl);
    const courses = this._addRowData(courseEl);
    const experiences = this._addRowData(experienceEl);

    this.data = { ...this.data, educations, courses, experiences };
  }

  _getData() {
    const {
      name,
      birthdate,
      residence,
      nationality,
      languagePrimary,
      languageSecondary,
      hobbies,
      linkedin,
      github,
      profileDescription,
      skills
    } = this.el;

    const skillArr = this._generateSkillsList(skills.value);

    this.data = {
      name: name.value,
      birthdate: birthdate.value,
      residence: residence.value,
      nationality: nationality.value,
      languagePrimary: languagePrimary.value,
      languageSecondary: languageSecondary.value,
      hobbies: hobbies.value,
      linkedin: linkedin.value,
      github: github.value,
      profileDescription: profileDescription.value,
      skills1: skillArr[0],
      skills2: skillArr[1],
      skills3: skillArr[2]
    };
  }

  _generateSkillsList = skills => {
    const skillArr = skills.trim().split(',');
    let counter = 0;

    // Distributes alls skills into 3 arrays as plain text, separated by a newline
    return skillArr.reduce(
      (prev, curr) => {
        prev[counter] = prev[counter].concat(`${curr}\n`);
        counter = counter === 2 ? 0 : ++counter;
        return prev;
      },
      ['', '', '']
    );
  };

  _loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
  }
}

export default Cv;
